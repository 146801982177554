import React from "react";
import { Box, Typography } from "@mui/material";
import { Modal } from "antd";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputComponent from "../../../components/InputComponent/InputComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import "./style.css";

const newAssociationFormValidationSchema = yup.object({
  hour: yup.string().required("O campo ”hora(s)” não pode ficar vazio"),
  minutes: yup.string().required("O campo ”minuto(s)” não pode ficar vazio"),
});

export default function ModalEditBalanceHour({
  openModal,
  closeModal,
  onFormSubmit,
  association,
}) {
  const { handleSubmit, control, watch, setValue, reset } = useForm({
    resolver: yupResolver(newAssociationFormValidationSchema),
  });

  const handleClearFilters = () => {
    reset();
    closeModal();
  };

  function handleAplicar(data) {
    onFormSubmit(data);
    reset();
    closeModal();
  }

  return (
    <Modal
      title={
        <span style={{ fontFamily: "Rawline", fontWeight: 600 }}>
          Editar saldo de horas
        </span>
      }
      open={openModal}
      width={600}
      onOk={closeModal}
      onCancel={() => {
        reset();
        closeModal();
      }}
      footer={null}
    >
      <Box
        display="flex"
        flexDirection="column"
        marginTop={3}
        gap={1}
        // style={{ fontFamily: "Rawline" }}
      >
        <Typography variant="p">
          Informe o tempo dedicado na seguinte data:
        </Typography>
        <Box display="flex" gap={1}>
          <Typography variant="p6">Data: </Typography>
          <Typography variant="p">01/01/2023</Typography>
        </Box>
        <form onSubmit={handleSubmit(handleAplicar)}>
          <Box display="flex" gap={2}>
            <Controller
              name="hour"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputComponent
                  {...field}
                  label="Hora(s)"
                  placeholder="00"
                  inputHelperText={error?.message}
                  inputType="hour"
                />
              )}
            />
            <Controller
              name="minutes"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputComponent
                  {...field}
                  label="Minuto(s)"
                  placeholder="00"
                  inputHelperText={error?.message}
                />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop={3} gap={2}>
            <ButtonComponent
              buttonType="secondary"
              title="Cancelar"
              onClick={handleClearFilters}
            />
            <ButtonComponent title="Salvar" icon="save" submit />
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
