import { Modal } from "antd";
import { Box, Typography } from "@mui/material";
import React from "react";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

export default function RequestHomologModal({
  openModal,
  closeModal,
  handleHomolog,
  report,
}) {
  return (
    <Modal
      title={
        <span style={{ fontFamily: "Rawline", fontWeight: 600 }}>
          Homologar relatório
        </span>
      }
      open={openModal}
      width={600}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
    >
      <Box
        display="flex"
        flexDirection="column"
        marginTop={3}
        gap={2}
        // style={{ fontFamily: "Rawline" }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="p">Relatório #{report.id}</Typography>
          <Typography variant="p6">{report.title}</Typography>
        </Box>
        <Typography variant="p">
          Ao enviar um relatório para homologação, seu supervisor será
          notificado. Confirma o envio?
        </Typography>
        <Box display="flex" justifyContent="flex-end" marginTop={1} gap={2}>
          <ButtonComponent
            buttonType="secondary"
            title="Cancelar"
            onClick={closeModal}
          />
          <ButtonComponent
            title="Enviar"
            icon="check"
            color="error"
            onClick={handleHomolog}
          />
        </Box>
      </Box>
    </Modal>
  );
}
