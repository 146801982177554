import React from "react";
import StyledToggleButtonGroup from "../StyledToggleButtonGroup";
import ImageToolbarEditor from "../ImageToolbarEditor";
import TableToolbarEditor from "../TableToolbarEditor";

function Illustration(props){

    const {editor, icon} = props;

    return (
        <StyledToggleButtonGroup size="small" exclusive aria-label="text editor" sx={{alignItems: 'center', padding: "0px 4px 0px 4px", height: 40}}>
            <ImageToolbarEditor editor={editor} icon={icon} />
            <TableToolbarEditor editor={editor}/>
      </StyledToggleButtonGroup>
    )
}

export default Illustration;