import React, { createContext, useContext, useState } from "react";

const RouteHistoryContext = createContext();

export function RouteHistoryProvider({ children }) {
  const [history, setHistory] = useState([]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <RouteHistoryContext.Provider value={{ history, setHistory }}>
      {children}
    </RouteHistoryContext.Provider>
  );
}

export const useRouteHistory = () => useContext(RouteHistoryContext);
