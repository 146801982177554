import React from "react";
import { Snackbar, IconButton } from "@mui/material";
import { useSnackbar } from "../../context/SnackbarProvider";

function MessageSnackbar() {
  const { snackbar, hideSnackbar } = useSnackbar();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    hideSnackbar();
  };

  let messageClass = "br-message neutral";
  let iconClass = "fas fa-info-circle fa-lg";

  if (snackbar.success) {
    messageClass = "br-message success";
    iconClass = "fas fa-check-circle fa-lg";
  } else if (snackbar.error) {
    messageClass = "br-message danger";
    iconClass = "fas fa-times-circle fa-lg";
  }

  return (
    <Snackbar
      open={snackbar.open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <div className={messageClass}>
        <div className="icon">
          <i className={iconClass} aria-hidden="true" />
        </div>
        <div className="content" role="alert">
          <span className="message-title">
            {snackbar.error
              ? "Erro."
              : snackbar.success
                ? "Sucesso."
                : "Informação"}
          </span>
          <span className="message-body"> {snackbar.message}</span>
        </div>
        <div className="close">
          <IconButton
            className="br-button circle small"
            aria-label="Fechar a mensagem alerta"
            onClick={handleClose}
          >
            <button
              className="br-button circle small"
              type="button"
              aria-label="Fechar a messagem alterta"
            >
              <i className="fas fa-times" aria-hidden="true" />
            </button>
          </IconButton>
        </div>
      </div>
    </Snackbar>
  );
}

export default MessageSnackbar;
