import { Modal } from "antd";
import { Box } from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import InputComponent from "../../../components/InputComponent/InputComponent";

const editTitleFormValidationSchema = yup.object({
  title: yup.string(),
});

export default function EditTitleModal({ openModal, closeModal, editedTitle }) {
  const { handleSubmit, reset, formState, control } = useForm({
    resolver: yupResolver(editTitleFormValidationSchema),
  });

  const handleEditTitle = (data) => {
    editedTitle(data);
  };

  return (
    <Modal
      title={
        <span style={{ fontFamily: "Rawline", fontWeight: 600 }}>
          Editar título
        </span>
      }
      open={openModal}
      width={500}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
    >
      <form onSubmit={handleSubmit(handleEditTitle)}>
        <Box
          display="flex"
          flexDirection="column"
          marginTop={3}
          gap={1}
          style={{ fontFamily: "Rawline" }}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputComponent
                  placeholder="Digite o título desejado..."
                  type="edit"
                  {...field}
                />
              )}
            />
            <Box display="flex" justifyContent="flex-end" marginTop={1} gap={2}>
              <ButtonComponent
                buttonType="secondary"
                title="Cancelar"
                onClick={closeModal}
              />
              <ButtonComponent submit title="Salvar" />
            </Box>
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
