import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StyledToggleButtonGroup from "../StyledToggleButtonGroup";

  
  const fontName = [
    "Arial",
    "Calibri",
    "Carlito",
  ]

const fontSizeString = Array.from({length: 100}, (_, i) => String(i + 1));


const ColorArrowDropDownIcon = styled(ArrowDropDownIcon)({
    color: 'blue', 
});

function Font (props){

    const { editor } = props; 
    const [alignment, setAlignment] = useState("");
    const [selectedFont, setSelectedFont] = useState(fontName[0]);
    const [selectedFontSize, setSelectedFontSize] = useState('12');

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const handleFont = (value) => {
        if (editor?.isActive("textStyle", { fontFamily: value })) {
            return value;
        }

        const newFont = fontName.find((font) => editor?.isActive("textStyle", { fontFamily: font }));

        if(newFont){
            setSelectedFont(newFont);
            return newFont;

        }

        return value !== null ? value : "";
    };

    const handleFontSize = (value) => {
        if (editor?.isActive("textStyle", { fontSize:  `${value}pt`})) {
            return value;
        }

        const newSize = fontSizeString.find((size) => editor?.isActive("textStyle", { fontSize:  `${size}pt` }));
        if(newSize){
            setSelectedFontSize(newSize);
            return newSize;
        }

        return value !== null ? value : "";
    };


    const handleInputChangeFont = (event, value) => {
        if ( value !== selectedFont){
            setSelectedFont(value);
            editor?.commands.setFontFamily(value);
        }
    }

    const handleInputChangeFontSize = (event) => {
        const {value} = event.target;
        if ( value !== selectedFontSize){
            setSelectedFontSize(value);
            editor?.commands.setFontSize(value);
        }
    }
    return (
        <StyledToggleButtonGroup
            size="small"
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            sx={{
            alignItems: "left",
                    display: "flex",
                    flexDirection: "column",
                    width: 'auto', 
                    height: 63,
                    padding: "0px 7px 0px 0px" 
            }}
        >

            <strong style={{width: 37, height: 23, padding: "0px 0px 4px 0px"}}>Fonte</strong>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Autocomplete
                    options={fontName}
                    noOptionsText="Sem Opções"
                    sx={{ 
                        width: 140, 
                        height: 40, 
                        padding: "0px 5px 0px 0px",
                        '& .MuiOutlinedInput-root': { padding: '0', height: '40px' }

                    }}
                    value={handleFont(selectedFont)}
                    onInputChange={(event, newInputValue) => {
                        handleInputChangeFont(event, newInputValue)
                    }}
                    defaultValue={fontName[0]}
                    renderInput={(params) => <TextField {...params} />}
                    openText="Abrir"
                    closeText="Fechar"
                    clearText="Limpar"
                    popupIcon={<ColorArrowDropDownIcon/>}
                />
                <TextField 
                    sx={{
                        width: 100, 
                        height: 40,
                        '& .MuiOutlinedInput-root': { padding: '0', height: '40px' },
                    }}
                    type="number"
                    InputProps={{
                        inputProps: { 
                            min: 0,
                        },
                    }}
                    value={handleFontSize(selectedFontSize)}
                    onChange={(event) => {
                        handleInputChangeFontSize(event)
                    }}
                    defaultValue='12'
                />
            </div>
        </StyledToggleButtonGroup>
    );
}

export default Font;