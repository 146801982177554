// eslint-disable-next-line import/no-extraneous-dependencies
import { Extension } from "@tiptap/core";

const LiteralTab = Extension.create({
    name: 'literalTab',

    addKeyboardShortcuts(){
        return {
            Tab: () => {
                const table = this.editor.getAttributes('tableCell'); 
                if(Object.keys(table).length === 0){
                    this.editor.commands.insertContent('    ');
                    return true;
                }
                this.editor.commands.goToNextCell();
                return true;
                
            }
        }
    }
});

export default LiteralTab;