import { Box, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { format, parseISO } from "date-fns";
import { Modal } from "antd";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

export default function AproveDisaproveRequestModal({
  openModal,
  closeModal,
  content = {},
  handleAprove,
  handleDisaprove,
}) {
  const theme = useTheme();

  const formatDate = (dateString) => {
    if (!dateString) return "Data não informada";
    const date = parseISO(dateString);
    return format(date, "dd/MM/yyyy 'às' HH:mm");
  };

  return (
    <Modal
      title={
        <span style={{ fontFamily: "Rawline", fontWeight: 600 }}>
          Homologar relatório
        </span>
      }
      open={openModal}
      width={600}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
    >
      <Box
        sx={{
          backgroundColor: theme.customColors?.greyWarm100 || "#f0f0f0",
          display: "flex",
          alignItems: "center",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 20 }} />
        <Box
          marginLeft={2}
          flexGrow={1}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography variant="body1">
            Este relatório aguarda homologação
          </Typography>
          <Typography variant="body1">
            Solicitação enviada por{" "}
            {content?.researcher?.username || "Usuário não informado"} em{" "}
            {formatDate(content?.updateDate)}.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" gap={2} marginTop={2}>
        <ButtonComponent
          title="Devolver"
          icon="cross"
          deleteColor
          onClick={handleDisaprove}
        />
        <ButtonComponent
          title="Homologar"
          icon="check"
          onClick={handleAprove}
        />
      </Box>
    </Modal>
  );
}
