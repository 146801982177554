import React, { useEffect, useState, forwardRef } from "react";

const TextArea = forwardRef(
  (
    { label, placeholder, maxLength, inputHelperText, value, ...props },
    ref,
  ) => {
    const [remainingChars, setRemainingChars] = useState(
      maxLength - (value?.length || 0),
    );

    useEffect(() => {
      setRemainingChars(maxLength - (value?.length || 0));
    }, [value, maxLength]);

    return (
      <div className="br-textarea large">
        {label && <label htmlFor="textarea-density-id2">{label}</label>}
        <textarea
          ref={ref}
          id="textarea-density-id2"
          placeholder={placeholder}
          style={{ minHeight: 150 }}
          maxLength={maxLength}
          value={value} // Assegura que o textarea utilize o valor controlado
          {...props}
        />
        <p className="text-base mt-1">
          Total de caracteres restantes: {remainingChars}
        </p>
        {inputHelperText && (
          <span className="feedback danger" role="alert">
            <i className="fas fa-times-circle" aria-hidden="true" />
            {inputHelperText}
          </span>
        )}
      </div>
    );
  },
);

export default TextArea;
