/* eslint-disable no-await-in-loop */


const getBase64FromClipboard = async (blob) => {
        
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
};

const getTextFromBlob = async (blob) => {
    const reader = new FileReader();
    reader.readAsText(blob)
    return new Promise((resolve) => {
        reader.onloadend =  () => {
            resolve(reader.result);
        }

    })

}

const imagePaste = async ( editor) => {

    if (navigator.clipboard && navigator.clipboard.read) {
        const clipboardContents = await navigator.clipboard.read();
        // eslint-disable-next-line no-restricted-syntax
        for (const item of clipboardContents) {
            // eslint-disable-next-line no-restricted-syntax
            if (item.types.includes("image/png")) {
                const blob = await item.getType("image/png");
            
                editor.commands.insertContent([
                    {
                        type: 'image',
                        attrs: {
                            alt: null,
                            src: await getBase64FromClipboard(blob),
                            title: null,
                            reference: 'Legenda padrão',
                            alignItems: 'center'
                        }
                    }
                ]); 
            }else{
                const type = await item.getType("text/html");
                const text = await getTextFromBlob(type);
                
                editor.commands.insertContentAt(editor.state.selection.anchor, text);

            
            }
        }
    }
}

export default imagePaste;