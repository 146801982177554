import React, { useEffect, useState } from "react";
import { Tooltip, ToggleButton} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListOl, faListUl } from "@fortawesome/free-solid-svg-icons";
import StyledToggleButtonGroup from "../StyledToggleButtonGroup";

const style = {
    color: "#888888",
    width: 40,
    height: 40,
    gap: "8px",
    padding: "0px 10px 0px 10px",
    borderRadius: "4px",
    border: "1px solid #888888 !important",
    minWidth: 0,
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: "#888888"
    }
}

function List(props){

    const {editor} = props;

    const [formats, setFormats] = useState(() => ["italic"]);

    const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
    };


    return(

        <StyledToggleButtonGroup
            size="small"
            value={formats}
            onChange={handleFormat}
            aria-label="text formatting"
            sx={{
                alignItems: 'center',
                padding: "0px 4px 0px 4px",
                height: 40
            }}
        >
        
            <Tooltip title="Marcadores" placement="top">
            <ToggleButton
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                value="bullettList"
                aria-label="bullettList"
                selected={editor?.isActive("bulletList")}
                sx={style}
            >
                <FontAwesomeIcon icon={faListUl} />
            </ToggleButton>
            </Tooltip>
            <Tooltip title="Numeração" placement="top">
            <ToggleButton
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                value="orderedList"
                aria-label="orderedList"
                selected={editor?.isActive("orderedList")}
                sx={style}
            >
                <FontAwesomeIcon icon={faListOl} />
            </ToggleButton>
            </Tooltip>
        </StyledToggleButtonGroup>

    );
}

export default List;