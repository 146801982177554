import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Modal } from "antd";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import "./style.css";

export default function ModalDeleteAssociationReport({
  openModal,
  closeModal,
  handleDelete,
  association,
}) {
  const [type, setType] = useState("");
  const [typeDescription, setTypeDescription] = useState("");
  const [indicM, setIndicM] = useState("");
  const [indicP, setIndicP] = useState("");
  const [observation, setObservation] = useState("");

  useEffect(() => {
    if (association) {
      setType(association.type || "");
      setTypeDescription(association.typeDescription || "");
      setIndicM(association.indicM || "");
      setIndicP(association.indicP || "");
      setObservation(association.observation || "");
    }
  }, [association]);
  return (
    <Modal
      title={
        <span style={{ fontFamily: "Rawline", fontWeight: 600 }}>
          Excluir associação
        </span>
      }
      open={openModal}
      width={500}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
    >
      <Box
        display="flex"
        flexDirection="column"
        marginTop={3}
        gap={1}
        // style={{ fontFamily: "Rawline" }}
      >
        <Typography variant="body1">
          A seguinte associação será excluída permanentemente:
        </Typography>
        <Box>
          <Box display="flex" gap={1}>
            <Typography variant="subtitle2">Tipo: </Typography>
            <Typography variant="body1">{type}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="subtitle2">Dado: </Typography>
            <Typography variant="body1">{typeDescription}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="subtitle2">Índice M: </Typography>
            <Typography variant="body1">{indicM}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="subtitle2">Índice P: </Typography>
            <Typography variant="body1">{indicP}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="subtitle2">Observações: </Typography>
            <Typography
              variant="body1"
              style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
            >
              {observation}
            </Typography>
          </Box>
        </Box>
        <Typography variant="body1">
          Essa ação é permanente e não pode ser desfeita. Deseja prosseguir com
          a exclusão?
        </Typography>
        <Box display="flex" justifyContent="flex-end" marginTop={1} gap={2}>
          <ButtonComponent
            buttonType="secondary"
            title="Cancelar"
            onClick={closeModal}
          />
          <ButtonComponent
            title="Excluir"
            icon="delete"
            deleteColor
            onClick={() => handleDelete(association.id)}
          />
        </Box>
      </Box>
    </Modal>
  );
}
