import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import InputComponent from "../../components/InputComponent/InputComponent";
import { login } from "../../services/Auth";
import GenericService from "../../services/GenericService";

const newLoginFormValidationSchema = yup.object({
  password: yup.string().required("O campo ”senha” não pode ficar vazio"),
  confirmPassword: yup.string().required("Senhas não conferem"),
});

export default function ResetInitial() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleSubmit, reset, formState, control } = useForm({
    resolver: yupResolver(newLoginFormValidationSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);

  const handleLogin = async (data) => {
    setIsLoading(true);
    localStorage.removeItem("id_token");
    try {
      const loginData = await GenericService.login(data);

      if (loginData && loginData.accessToken) {
        await login(loginData.accessToken);
        navigate("/relatorios");
      } else {
        setErrorLogin(true);
      }
    } catch (error) {
      setErrorLogin(true);
    }
    setIsLoading(false);
    reset();
  };

  return (
    <Box
      sx={{
        backgroundColor: "#0C326F",
      }}
      height="100vh"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <form onSubmit={handleSubmit(handleLogin)}>
          <Box
            // height={487}
            width={408}
            sx={{ backgroundColor: theme.customColors.white }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box padding="28px 0 28px 0">
              <Typography variant="h3">Bem-vindo ao SIGAR!</Typography>
            </Box>
            <Box padding="0 35px 20px 35px">
              <Typography variant="p">
                Para continuar, você precisa alterar a senha temporária que lhe
                foi fornecida.
              </Typography>
            </Box>
            <Box
              gap="15px"
              display="flex"
              flexDirection="column"
              width="100%"
              padding="0 20px 40px 20px"
            >
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <InputComponent
                    label="Senha"
                    type="password"
                    placeholder="Digite sua senha"
                    error={error || errorLogin ? "danger" : ""}
                    inputHelperText={error?.message}
                    {...field}
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <InputComponent
                    type="password"
                    placeholder="Digite sua senha novamente"
                    error={error || errorLogin ? "danger" : ""}
                    inputHelperText={error?.message}
                    {...field}
                  />
                )}
              />

              <LoadingButton
                variant="contained"
                sx={{
                  width: 360,
                  height: 40,
                  borderRadius: 5,
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: 16.8,
                  marginTop: 1,
                  "&.MuiLoadingButton-loading": {
                    backgroundColor: "primary.main",
                  },
                  ".MuiLoadingButton-loadingIndicator": {
                    color: "white",
                  },
                }}
                type="submit"
                loading={isLoading}
              >
                Continuar
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
