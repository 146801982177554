import React, { useEffect, useState } from "react";
import { Tooltip, ToggleButton} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faRedo } from "@fortawesome/free-solid-svg-icons";
import StyledToggleButtonGroup from "../StyledToggleButtonGroup";


const style = {
    width: 40,
    height: 40,
    gap: "8px",
    padding: "0px 10px 0px 10px",
    borderRadius: "4px",
    border: "1px solid #888888 !important",
    minWidth: 0,
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: "#888888"
    }
}
function History (props){

    const {editor} = props;
    return (
        <StyledToggleButtonGroup size="small" exclusive aria-label="text editor" sx={{alignItems: 'center', padding: "0px 4px 0px 4px", height: 40}}>
            <Tooltip title="Desfazer" placement="top">
            <ToggleButton 
                onClick={() => editor.chain().focus().undo().run()}
                value="undo"
                aria-label="undo"
                sx={style}
            >
                <FontAwesomeIcon icon={faUndo} />
            </ToggleButton>
            </Tooltip>
            <Tooltip title="Refazer" placement="top">
            <ToggleButton
                onClick={() => editor.chain().focus().redo().run()}
                value="redo"
                aria-label="redo"
                sx={style}
            >
                <FontAwesomeIcon icon={faRedo} />
            </ToggleButton>
            </Tooltip>
        </StyledToggleButtonGroup>
    );
}

export default History;