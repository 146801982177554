import React from "react";
import { Navigate } from "react-router-dom";
import { getUserInfoFromToken } from "../services/Auth";

function RoleProtectedRoute({ children, allowedRoles }) {
  const isAuthenticated = localStorage.getItem("id_token");
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  const userRoles = getUserInfoFromToken()?.roles || [];

  const hasRequiredRole = userRoles.some((role) => allowedRoles.includes(role));

  if (!hasRequiredRole) {
    return <Navigate to="/relatorios" replace />;
  }

  return children;
}

export default RoleProtectedRoute;
