import { jwtDecode } from "jwt-decode";

export const TOKEN_KEY = "id_token";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const PREVIOUS_ALLOWED_URL = "previousAllowedUrl";

export const LAST_URL = "lastURL";

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const setLastUrl = (url) => {
  localStorage.setItem(LAST_URL, url);
};

export const getUserEmail = () => {
  if (!localStorage.getItem(TOKEN_KEY)) {
    return null;
  }
  try {
    return JSON.parse(
      window.atob(localStorage.getItem(TOKEN_KEY).split(".")[1]),
    ).sub;
  } catch (e) {
    return null;
  }
};

export const setPreviousAllowedUrl = (url) => {
  localStorage.setItem(PREVIOUS_ALLOWED_URL, url);
};

export const getPreviousAllowedUrl = () =>
  localStorage.getItem(PREVIOUS_ALLOWED_URL);

export const getLastUrl = () => localStorage.getItem(LAST_URL);

export const removeLastUrl = () => localStorage.removeItem(LAST_URL);

export const logout = async () => {
  await localStorage.removeItem(TOKEN_KEY);
};

export const getUserInfoFromToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    const decoded = jwtDecode(token);
    return {
      name: decoded.userName,
      roles: decoded.roles,
      team: decoded.team,
      sub: decoded.sub,
    };
  }
  return null;
};
