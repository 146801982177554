import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { getUserInfoFromToken, logout, TOKEN_KEY } from "../../services/Auth";

export default function Header() {
  const navigate = useNavigate();
  const userInfo = getUserInfoFromToken();

  const handleLogout = async () => {
    await new Promise((resolve) => {
      localStorage.removeItem("id_token");
      resolve();
    });
    navigate("/", { replace: true });
  };

  return (
    <header className="br-header">
      <div className="container-fluid">
        <div className="header-top">
          <div className="header-menu">
            <div className="header-info">
              <div className="header-title">SIGAR</div>
              <div className="header-subtitle" style={{ fontWeight: 400 }}>
                Sistema de Gestão de Análise de Risco para atividade de
                Repressão
              </div>
            </div>
          </div>
          <div className="header-actions">
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                color: "#CF4900",
                fontWeight: 600,
              }}
            >
              <i className="fas fa-user-shield fa-sm" aria-hidden="true" />
              <div className="header-subtitle">Acesso Restrito</div>
            </div>
            <span className="br-divider vertical mx-3 my-1" />
            <div className="header-subtitle">{userInfo?.name || "NA"}</div>
            <div className="header-login">
              <div className="header-sign-in">
                <button
                  className="br-sign-in small bg-blue-warm-vivid-70"
                  type="button"
                  style={{ color: "white" }}
                  data-trigger="login"
                  onClick={handleLogout}
                >
                  <i className="fas fa-sign-in-alt fa-1x" aria-hidden="true" />
                  <span
                    className="d-sm-inline"
                    style={{ fontSize: 14, fontWeight: 700 }}
                  >
                    Sair
                  </span>
                </button>
              </div>
              <div className="header-avatar" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
