import React from "react";
import { GithubPicker } from "react-color";

const colors = [
    "#000000",
    "#B80000",
    "#DB3E00",
    "#FCCB00",
    "#008B02",
    "#006B76",
    "#1273DE",
    "#004DCF",
    "#5300EB",
    "#EB9694",
    "#FAD0C3",
    "#FEF3BD",
    "#C1E1C5",
    "#BEDADC",
    "#C4DEF6",
    "#D4C4FB",
  ];

function FontColorToolbarEditor(props) {
    const {editor, src, fontColor, setFontColor} = props;

    const handleColorChangeComplete = (color) => {
        if(src==="toolbar"){
            setFontColor({ color: color.hex });
            editor.chain().focus().setColor(color.hex).run();
        }else{
            editor.chain().focus().setCellAttribute('backgroundColor', color.hex).run();
        }
    };
   
    return(
        <GithubPicker
        color={fontColor.color}
        onChangeComplete={handleColorChangeComplete}
        colors={colors}
        triangle="hide"
        width={220}
        />
    )
}

export default FontColorToolbarEditor;