import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { logout } from "../services/Auth";

export default function ProtectedRoute({ children }) {
  const token = localStorage.getItem("id_token");

  if (!token) {
    return <Navigate to="/" replace />;
  }

  // eslint-disable-next-line camelcase
  const tokenDecode = jwtDecode(token);
  // eslint-disable-next-line camelcase
  const isTokenExpired = tokenDecode.exp * 1000 < Date.now();
  if (!tokenDecode || isTokenExpired) {
    logout();
    return <Navigate to="/" replace />;
  }

  return children;
}
