import React, { useState, useEffect, forwardRef, useRef } from "react";
import { ClickAwayListener, Tooltip } from "@mui/material";
import "./style.css";

const iconMap = {
  type: "fa-file",
  status: "fa-tasks",
  users: "fa-users",
  supervisor: "fa-user-tie",
  user: "fa-user",
};

const SelectComponent = forwardRef(
  (
    {
      label,
      placeholder,
      type,
      isReadOnly,
      options,
      selectHelperText,
      disableArrow,
      displayKey,
      isDisabled,
      ...props
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [displayValue, setDisplayValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [dropdownReopened, setDropdownReopened] = useState(false);

    const inputRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
      if (props.value) {
        const valueToShow = displayKey
          ? props.value[displayKey]
          : typeof props.value === "object"
            ? Object.values(props.value)[1]
            : props.value;
        setDisplayValue(valueToShow);
      } else {
        setDisplayValue("");
      }
    }, [props.value, displayKey]);

    useEffect(() => {
      if (displayValue && !dropdownReopened) {
        const newFilteredOptions = options.filter((option) => {
          const value = displayKey
            ? option[displayKey]
            : Object.values(option)[1];

          if (typeof value === "string") {
            return value.toLowerCase().includes(displayValue.toLowerCase());
          }
          return false;
        });
        setFilteredOptions(newFilteredOptions);
      } else {
        setFilteredOptions(options);
      }
    }, [displayValue, options, dropdownReopened, displayKey]);

    const handleSelect = (option) => {
      setSelectedValue(option);
      setDisplayValue(
        displayKey ? option[displayKey] : option[Object.keys(option)[1]],
      );
      setIsOpen(false);
      setDropdownReopened(false);
      if (props.onChange) {
        props.onChange(option);
      }
    };

    const handleInputChange = (event) => {
      const { value } = event.target;
      setDisplayValue(value);
      setDropdownReopened(false);
    };

    const toggleDropdown = () => {
      if (!isDisabled) {
        setIsOpen(!isOpen);
        setDropdownReopened(!isOpen);
      }
    };

    const renderIcon = (iconType) => (
      <div className="input-icon">
        <i className={`fas ${iconMap[iconType]}`} aria-hidden="true" />
      </div>
    );

    return (
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <div>
          <div className={`br-select ${isDisabled ? "disabled" : ""}`}>
            <div className="br-input">
              <label htmlFor="select-simple">{label}</label>
              <div className="input-icon-container">
                {iconMap[type] && renderIcon(type)}
                <input
                  id="select-simple"
                  type="text"
                  placeholder={placeholder}
                  className="input-with-icon"
                  value={displayValue}
                  readOnly={isReadOnly}
                  ref={inputRef}
                  onChange={handleInputChange}
                  onClick={toggleDropdown}
                  autoComplete="off"
                  disabled={isDisabled}
                />
              </div>
              {!disableArrow && (
                <button
                  ref={buttonRef}
                  className="br-button"
                  type="button"
                  aria-label="Exibir lista"
                  tabIndex="-1"
                  data-trigger="data-trigger"
                  onClick={toggleDropdown}
                  disabled={isDisabled}
                >
                  <Tooltip title={isOpen ? "Fechar" : "Expandir"}>
                    <i
                      className={`fas ${isOpen ? "fa-caret-up" : "fa-caret-down"}`}
                      aria-hidden="true"
                    />
                  </Tooltip>
                </button>
              )}
            </div>
            {isOpen && filteredOptions && filteredOptions.length > 0 && (
              <div className="br-list" style={{ display: "block", zIndex: 12 }}>
                {filteredOptions.map((option) => {
                  const keys = Object.keys(option);
                  const value = displayKey
                    ? option[displayKey]
                    : option[keys[1]];

                  return (
                    <div
                      key={option[keys[0]]}
                      className={`br-item ${isDisabled ? "disabled" : ""}`}
                      onClick={() => !isDisabled && handleSelect(option)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !isDisabled) {
                          handleSelect(option);
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <div className="br-radio">
                        <input
                          id={`rb-${option[keys[0]]}`}
                          type="radio"
                          name="estados-simples"
                          value={option[keys[0]]}
                          disabled={isDisabled}
                        />
                        <label htmlFor={`rb-${option[keys[0]]}`}>{value}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {selectHelperText && (
            <span className="feedback danger" role="alert">
              <i className="fas fa-times-circle" aria-hidden="true" />
              {selectHelperText}
            </span>
          )}
        </div>
      </ClickAwayListener>
    );
  },
);

export default SelectComponent;
