import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faFile,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export default function ReferenceComponent({ content }) {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant="h2">Referenciar relatório</Typography>
      {!content || content.length === 0 ? (
        <Box
          borderRadius={1}
          margin={1}
          padding={2}
          display="flex"
          alignItems="center"
          gap={2}
          sx={{ backgroundColor: theme.customColors.greyWarm100 }}
        >
          <FontAwesomeIcon icon={faInfoCircle} size="lg" />
          <Typography variant="p">
            Para referenciar um relatório homologado, inicie a criação através
            do fluxo &quot;Relatórios homologados » Usar como referência&quot;.
          </Typography>
        </Box>
      ) : (
        content.map((item, index) => (
          <Box
            borderRadius={1}
            margin={1}
            padding={2}
            display="flex"
            alignItems="center"
            gap={2}
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
          >
            <FontAwesomeIcon icon={faFile} size="lg" />
            <Typography variant="p">{item.title}</Typography>
            <ButtonBase>
              <FontAwesomeIcon
                icon={faChevronRight}
                size="1x"
                color={theme.customColors.blueWarm700}
              />
            </ButtonBase>
          </Box>
        ))
      )}
    </Box>
  );
}
