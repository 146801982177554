import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Cria o contexto
const SnackbarContext = createContext();

// Hook para acessar o contexto de qualquer parte da aplicação
export const useSnackbar = () => useContext(SnackbarContext);

export function SnackbarProvider({ children }) {
  const [snackbar, setSnackbar] = useState({
    message: "",
    success: false,
    erro: false,
    neutral: false,
    open: false,
    timestamp: null, // Adiciona um campo para o timestamp
  });

  const location = useLocation(); // Hook do React Router para monitorar mudanças de rota

  // Função para mostrar o Snackbar com um timestamp opcional
  const showSnackbar = (config) => {
    const { timestamp } = config;
    setSnackbar({
      ...config,
      open: true,
      timestamp: timestamp || null, // Define o timestamp, se fornecido
    });

    // Se houver um timestamp, fechar o snackbar após 5 segundos
    if (timestamp) {
      setTimeout(() => {
        setSnackbar((prevState) => ({ ...prevState, open: false }));
      }, 5000); // 5 segundos de delay
    }
  };

  // Função para esconder o Snackbar manualmente
  const hideSnackbar = () => {
    setSnackbar((prevState) => ({ ...prevState, open: false }));
  };

  // Fechar o Snackbar quando a rota mudar
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!snackbar.timestamp) {
      // Se não houver timestamp, fechar imediatamente ao mudar de página
      hideSnackbar();
    } else {
      // Se houver timestamp, aguardar os 5 segundos para fechar ao mudar de página
      const timeout = setTimeout(() => {
        hideSnackbar();
      }, 5000);

      return () => clearTimeout(timeout); // Limpa o timeout se o componente for desmontado antes
    }
  }, [location, snackbar.timestamp]); // O efeito é reexecutado quando a localização ou o timestamp mudam

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SnackbarContext.Provider value={{ snackbar, showSnackbar, hideSnackbar }}>
      {children}
    </SnackbarContext.Provider>
  );
}
