import React, { forwardRef } from "react";

const CheckboxComponent = forwardRef(
  ({ label, description, onChange, value }, ref) => (
    <div>
      {label && <p className="label mb-2">{label}</p>}
      <div className="br-checkbox">
        <input
          ref={ref}
          id="h-checkbox-1"
          name="h-checkbox-1"
          type="checkbox"
          onChange={onChange}
          checked={value}
        />
        <label htmlFor="h-checkbox-1">{description}</label>
      </div>
    </div>
  ),
);

export default CheckboxComponent;
