import React, { useEffect, useState } from "react";
import { Box, Button, Menu, MenuItem, ListItemIcon, ListItemText, Divider, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    faTable,
    faArrowRight,
    faArrowLeft,
    faArrowDown,
    faArrowUp,
    faTrashAlt,
    faArrowsAltH,
    faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import StyledToggleButtonGroup from "./StyledToggleButtonGroup";
import FontColorToolbarEditor from "./FontColorToolbarEditor";


function TableToolbarEditor (props) {

    const { editor } = props;

    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [showColor, setShowColor] = useState(false);
    const [fontColor, setFontColor] = useState({ color: "#000000" });


    let isMenuCliked = Boolean(false);

    const openMenu = Boolean(anchorElMenu);

    const menuId = openMenu ? "basic-menu" : undefined;

  
    const handleColorClick = () => {
        setShowColor(!showColor)
    };
    
    const handleMenuClick = (event) => {
        isMenuCliked = true;
        setAnchorElMenu(event.currentTarget);
      };
  
    const handleCloseMenu = () => {
        isMenuCliked = false;
        setAnchorElMenu(null);
        setShowColor(false);
      };

    const handleInsertTable = () => {
        editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run();
        setDisabled(false);
    }

    const handleDeleteTable = () => {
        editor.chain().focus().deleteTable().run();
        setDisabled(true)
    }
    
    useEffect(() => {
        const containTable = editor?.getHTML();
        if(containTable && containTable.includes('<table>')){
           setDisabled(false);
        }
    }, [editor, disabled]);

	return(
    	<StyledToggleButtonGroup size="small" sx={{alignItems: 'center'}}>
            <Tooltip title="Inserir tabela" placement="top" style={{margin: 4}}>
                <Box display="flex" flexDirection="row">
                    <Button
                        aria-describedby={menuId}
                        onClick={handleMenuClick}
                        value={isMenuCliked}
                        sx={{ 
                                color: '#888888',
                                width: 40,
                                height: 40,
                                gap: "8px",
                                padding: "0px 10px 0px 10px",
                                borderRadius: "4px",
                                border: "1px solid #888888 !important",
                                minWidth: 0,
                            }}
                        >
                    <FontAwesomeIcon icon={faTable} />
                    </Button>
                </Box>
            </Tooltip>
            <Menu
                id={menuId}
                anchorEl={anchorElMenu}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <MenuItem onClick={handleInsertTable}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faTable} /> 
                    </ListItemIcon>
                    <ListItemText>Incluir Tabela</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDeleteTable} disabled={disabled}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faTrashAlt} /> 
                    </ListItemIcon>
                    <ListItemText>Excluir Tabela</ListItemText>
                </MenuItem>
                <Divider flexItem orientation="horizontal" sx={{ mx: 0.5, my: 1 }} />
                <MenuItem 
                    onClick={() => editor.chain().focus().addRowBefore().run()}
                    disabled={disabled}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faArrowUp} /> 
                    </ListItemIcon>
                    <ListItemText>Inserir Linha Acima</ListItemText>
                </MenuItem>
                <MenuItem 
                    onClick={() => editor.chain().focus().addRowAfter().run()}
                    disabled={disabled}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faArrowDown} /> 
                    </ListItemIcon>
                    <ListItemText>Inserir Linha Abaixo</ListItemText>
                </MenuItem>
                <MenuItem 
                    onClick={() => editor.chain().focus().deleteRow().run()}
                    disabled={disabled}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faTrashAlt} /> 
                    </ListItemIcon>
                    <ListItemText>Excluir Linha</ListItemText>
                </MenuItem>
                <Divider flexItem orientation="horizontal" sx={{ mx: 0.5, my: 1 }} />
                <MenuItem 
                    onClick={() => editor.chain().focus().addColumnAfter().run()}
                    disabled={disabled}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faArrowRight} /> 
                    </ListItemIcon>
                    <ListItemText>Inserir Coluna à Direita</ListItemText>
                </MenuItem>
                <MenuItem 
                    onClick={() => editor.chain().focus().addColumnBefore().run()}
                    disabled={disabled}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faArrowLeft} /> 
                    </ListItemIcon>
                    <ListItemText>Inserir Coluna à Esquerda</ListItemText>
                </MenuItem>
                <MenuItem 
                    onClick={() => editor.chain().focus().deleteColumn().run()}
                    disabled={disabled}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faTrashAlt} /> 
                    </ListItemIcon>
                    <ListItemText>Excluir Coluna</ListItemText>
                </MenuItem>
                <Divider flexItem orientation="horizontal" sx={{ mx: 0.5, my: 1 }} />
                <MenuItem 
                    onClick={() => editor.chain().focus().mergeOrSplit().run()}
                    disabled={disabled}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faArrowsAltH} /> 
                    </ListItemIcon>
                    <ListItemText>Mesclar ou Separar células</ListItemText>
                </MenuItem>
                <MenuItem 
                    disabled={disabled} 
                    onClick={handleColorClick}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </ListItemIcon>
                    <ListItemText>Colorir célula </ListItemText>&nbsp; 
                </MenuItem>
                {showColor && (
                    <MenuItem>
                        <FontColorToolbarEditor editor={editor} fontColor={fontColor} setFontColor={setFontColor}/>
                    </MenuItem>
                )}
            </Menu>
        </StyledToggleButtonGroup>
	);
}
export default TableToolbarEditor;