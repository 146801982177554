import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import GenericService from "../../services/GenericService";

function PDFPage(props) {
  const { editor, waterwark } = props;
  const [pdf, setPdf] = useState(null);

  useEffect(() => {
    if (editor && waterwark) {
      const params = { params: editor.getHTML(), image: waterwark };
      const headers = { "Content-type": "application/json; charset=UTF-8" };

      GenericService.renderPDF("pdfrender/build", params, headers)
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          setPdf(`${url}#toolbar=0`);
        })
        .catch((err) => console.error(err)); // eslint-disable-line no-console
    }
  }, [editor, waterwark]);

  return (
    <Box
      p={2}
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="row"
      padding="0"
      justifyContent="center"
    >
      {pdf ? (
        <iframe src={pdf} width="100%" height="100%" title="preview" />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}

export default PDFPage;
