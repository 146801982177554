import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "@govbr-ds/core/dist/core.min.css";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <App />,
);
