import React, { useContext, useState } from "react";
import {
    Tooltip,
    ToggleButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
} from "@fortawesome/free-solid-svg-icons";
import StyledToggleButtonGroup from "../StyledToggleButtonGroup";
import EditorContext from "../../../context/Editor/EditorContext";

const style = {
  // color: "#888888",
  width: 40,
  height: 40,
  gap: "8px",
  padding: "0px 10px 0px 10px",
  borderRadius: "4px",
  border: "1px solid #888888 !important",
  minWidth: 0,
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#888888"
  }
}

function Alignment(props){
    const {editor} = props;

    const [alignment, setAlignment] = useState("");
    const {styleImage, idImageSelect, setStyleImage} = useContext(EditorContext);
    
    const handleClickAlign = (event,alignItems) => {
        const img = editor?.view.state.selection.node ?? null;
        if(img !== null) {
          let convertAlign;
          if(alignItems === 'left'){
            convertAlign = 'flex-start'
          }else if(alignItems === 'right'){
            convertAlign = 'flex-end'
          }else if(alignItems === 'justify'){
            convertAlign = 'stretch'
          }else{
            convertAlign = 'center'
          }
    
          const styleImageObj = Object.values(styleImage); 
          const index = styleImageObj.findIndex(image => image.id === idImageSelect);
    
          // const updateStyleImage = { ...styleImageObj};
          // const index = updateStyleImage.findIndex(imgId => imgId.id === idImageSelect);
          styleImageObj[index].style.alignItems = convertAlign;
          setStyleImage(styleImageObj);
          img.attrs.alignItems = convertAlign;
        }else{
          editor.chain().focus().setTextAlign(alignItems).run();
        }
    
      };

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <StyledToggleButtonGroup
        size="small"
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        sx={{
          alignItems: 'center',
          // width: 201,
          height: 40,
          padding: "0px 4px 0px 4px"
        }}
      >
        <Tooltip title="Alinhar a esquerda" placement="top">
          <ToggleButton
            aria-label="left aligned"
            onClick={(e) => handleClickAlign(e, "left")}
            selected={editor?.isActive({ textAlign: "left" })}
            value="left"
            sx={style}
          >
            <FontAwesomeIcon icon={faAlignLeft} />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Alinhar ao centro" placement="top">
          <ToggleButton
            aria-label="centered"
            onClick={(e) => handleClickAlign(e, "center")}
            selected={editor?.isActive({ textAlign: "center" })}
            value="center"
            sx={style}
          >
            <FontAwesomeIcon icon={faAlignCenter} />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Alinhar a direita" placement="top">
          <ToggleButton
            aria-label="right aligned"
            onClick={(e) => handleClickAlign(e, "right")}
            selected={editor?.isActive({ textAlign: "right" })}
            value="right"
            sx={style}
          >
            <FontAwesomeIcon icon={faAlignRight} />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Justificar" placement="top">
          <ToggleButton
            aria-label="justified"
            onClick={(e) => handleClickAlign(e, "justify")}
            selected={editor?.isActive({ textAlign: "justify" })}
            value="justify"
            is
            sx={style}
          >
            <FontAwesomeIcon icon={faAlignJustify} />
          </ToggleButton>
        </Tooltip>
       
      </StyledToggleButtonGroup>
    )
}

export default Alignment;