import { Modal } from "antd";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import TextArea from "../TextArea/TextArea";

export default function ReturnReportModal({
  openModal,
  closeModal,
  handleReturn,
  content,
}) {
  const schema = yup.object().shape({
    reason: yup.string().required("O motivo da devolução é obrigatório."),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    handleReturn(data);
    reset();
    closeModal();
  };

  return (
    <Modal
      title={
        <span style={{ fontFamily: "Rawline", fontWeight: 600 }}>
          Devolver relatório
        </span>
      }
      open={openModal}
      width={600}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" marginTop={3} gap={2}>
          <Box display="flex" flexDirection="column">
            <Typography variant="p">Relatório #{content.id}</Typography>
            <Typography variant="p6">{content.title}</Typography>
          </Box>
          <Typography variant="p">
            O relatório será devolvido ao pesquisador, que poderá editar antes
            de solicitar uma nova homologação.
          </Typography>
          <Controller
            name="reason"
            control={control}
            render={({ field, fieldState }) => (
              <TextArea
                placeholder="Informe o motivo da devolução"
                maxLength={300}
                error={!!fieldState.error}
                inputHelperText={
                  fieldState.error ? fieldState.error.message : null
                }
                {...field}
              />
            )}
          />
          <Box display="flex" justifyContent="flex-end" marginTop={1} gap={2}>
            <ButtonComponent
              buttonType="secondary"
              title="Cancelar"
              onClick={closeModal}
            />
            <ButtonComponent
              title="Devolver"
              icon="cross"
              deleteColor
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
