import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../components/InputComponent/InputComponent";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import TextArea from "../../components/TextArea/TextArea";
import CheckboxComponent from "../../components/CheackboxComponent/CheckboxComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import ReferenceComponent from "../../components/ReferenceComponent/ReferenceComponent";
import GenericService from "../../services/GenericService";
import { getUserInfoFromToken } from "../../services/Auth";
import { useSnackbar } from "../../context/SnackbarProvider";

export const editReportLoader = async ({ params }) => {
  const user = getUserInfoFromToken();
  if (!user) {
    return false;
  }
  const teamNumber = user.team.replace("RF", "");
  try {
    const responsePesquisador = await GenericService.findAllList(
      `team/investigate/${teamNumber}`,
    );
    const responseSupervisor = await GenericService.findAllList(
      `team/supervisor/${teamNumber}`,
    );
    const responseTiposRelatorio =
      await GenericService.findAllList("type-report");
    const responseTeams = await GenericService.findAllList("teams");
    const responseReport = await GenericService.findOne(
      "find-reporting",
      params.id,
    );

    return {
      pesquisadorData: responsePesquisador.data,
      supervisorData: responseSupervisor.data,
      tiposRelatorio: responseTiposRelatorio.data,
      teams: responseTeams.data,
      reportData: responseReport.data,
    };
  } catch (error) {
    return {
      pesquisadorData: [],
      supervisorData: [],
      tiposRelatorio: [],
      teams: [],
      reportData: [],
      error:
        "Houve um erro ao carregar os dados. Por favor, tente novamente mais tarde.",
    };
  }
};

const newReportFormValidationSchema = yup.object({
  title: yup.string().required("O campo ”título” não pode ficar vazio"),
  researcher: yup
    .object()
    .required("O campo ”pesquisador” não pode ficar vazio"),
  supervisor: yup.object(),
  ovr: yup.string().required("O campo ”OVR” não pode ficar vazio"),
  typeReporting: yup
    .object()
    .required(`O campo "tipo de relatório" não pode ficar vazio`),
  confidential: yup.boolean(),
  justification: yup.string(),
});

export default function EditReport() {
  const loaderData = useLoaderData();
  const { pesquisadorData } = loaderData;
  const { supervisorData } = loaderData;
  const { tiposRelatorio } = loaderData;
  const { teams } = loaderData;
  const { reportData } = loaderData;
  const dataReport = new Date(reportData.creationDate);
  const formattedDate = dataReport.toLocaleDateString("pt-BR");
  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(newReportFormValidationSchema),
  });
  const navigate = useNavigate();
  const userInfo = getUserInfoFromToken();
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();

  const handleEditReport = async (data) => {
    try {
      const payload = {
        ...data,
        id: reportData.id,
        researcher: reportData.researcher,
      };
      const response = await GenericService.update("put-order", payload);
      // eslint-disable-next-line no-constant-condition
      if (response.status === 200 || 500) {
        showSnackbar({
          success: true,
          message: "Relatório editado com sucesso!",
          timestamp: true,
        });
        navigate("/relatorios");
      }
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro ao editar o relatório: ${error}`,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleEditReport)}>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          marginY={5}
        >
          <Typography variant="h4" alignSelf="center">
            Editar relatório
          </Typography>

          <Box display="flex" gap={2}>
            <ButtonComponent
              title="Cancelar"
              type="outlined"
              onClick={() => navigate("/relatorios")}
              buttonType="secondary"
            />
            <ButtonComponent title="Salvar alterações" icon="save" submit />
          </Box>
        </Box>
        <Grid container display="flex" gap={3} spacing={2}>
          <Grid container xs={8} padding={2} spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                defaultValue={reportData.title}
                render={({ field, fieldState: { error } }) => (
                  <InputComponent
                    label="Título"
                    placeholder="Digite o título desejado..."
                    type="edit"
                    maxLength={100}
                    inputHelperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="researcher"
                control={control}
                defaultValue={reportData.researcher}
                render={({ field, fieldState: { error } }) => (
                  <SelectComponent
                    label="Pesquisador"
                    placeholder="Digite o nome do pesquisador..."
                    options={pesquisadorData}
                    selectHelperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="supervisor"
                control={control}
                defaultValue={reportData.supervisor}
                render={({ field, fieldState: { error } }) => (
                  <SelectComponent
                    label="Supervisor"
                    placeholder="Digite o nome do supervisor..."
                    options={supervisorData}
                    selectHelperText={error?.message}
                    isDisabled={!userInfo?.roles?.includes("administrador")}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="ovr"
                control={control}
                defaultValue={reportData.ovr}
                render={({ field, fieldState: { error } }) => (
                  <InputComponent
                    label="Código da OVR"
                    placeholder="Digite o código da OVR..."
                    type="code"
                    maxLength={100}
                    inputHelperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="typeReporting"
                control={control}
                defaultValue={reportData.typeReporting}
                render={({ field, fieldState: { error } }) => (
                  <SelectComponent
                    label="Tipo"
                    placeholder="Selecione o tipo de relatório..."
                    options={tiposRelatorio}
                    selectHelperText={error?.message}
                    displayKey="description"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="confidential"
                control={control}
                defaultValue={reportData.confidential}
                render={({ field }) => (
                  <CheckboxComponent
                    label="Privacidade"
                    description="Definir como sigiloso"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="justification"
                control={control}
                defaultValue={reportData.justification}
                render={({ field }) => (
                  <TextArea
                    label="Justificatica (opcional)"
                    placeholder="Insira sua justificativa..."
                    maxLength={255}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid container padding={3}>
              <Grid item xs={4}>
                <Box>
                  <Typography variant="p6">Data de início</Typography>
                </Box>
                <Box>
                  <Typography variant="p">{formattedDate}</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography variant="p6">Equipe responsável</Typography>
                </Box>
                <Box>
                  <Typography variant="p">{userInfo?.team || "NA"}</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography variant="p6">Modelo</Typography>
                </Box>
                <Box>
                  <Typography variant="p">Modelo XYZ (v1)</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} boxShadow={3} padding={2} alignSelf="flex-start">
            {/* <ReferenceComponent /> */}
            <Box>
              <Typography variant="h2">Observação</Typography>
              <Box
                borderRadius={1}
                margin={1}
                padding={2}
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ backgroundColor: theme.customColors.greyWarm100 }}
              >
                <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                <Typography variant="p">
                  Apenas o administrador pode editar o supervisor responsável
                  pelo relatório.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
