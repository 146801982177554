import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TableComponent from "../../components/Table/TableComponent";
import GenericService from "../../services/GenericService";
import { getUserInfoFromToken } from "../../services/Auth";
import { useSnackbar } from "../../context/SnackbarProvider";
import ModalDeleteBalanceHour from "./components/ModalDeleteBalanceHour";
import ModalEditBalanceHour from "./components/ModalEditBalanceHour";
import ModalRegisterBalanceHour from "./components/ModalRegisterBalanceHour";

export const reportsDetailsLoader = async ({ params }) => {
  const responseReport = await GenericService.findOne(
    "find-reporting",
    params.id,
  );
  const responseAddons = await GenericService.findAllList(
    `addons/reporting-order/${params.id}`,
  );
  if (responseReport.status === 200 && responseAddons) {
    return {
      reportData: responseReport.data,
      addonsData: responseAddons.data,
    };
  }
  return {};
};

const newAddonFormValidationSchema = yup.object({
  description: yup
    .string()
    .required("O campo ”observação” não pode ficar vazio"),
});

export default function BalanceHours() {
  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(newAddonFormValidationSchema),
    defaultValues: {
      description: "",
    },
  });
  const navigate = useNavigate();
  const [reportDetails, setReportDetails] = useState(
    useLoaderData().reportData,
  );
  const [addOns, setAddOns] = useState(useLoaderData().addonsData);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortInfo, setSortInfo] = useState({ id: "", type: "" });
  const [modalRegister, setModalRegister] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const userInfo = getUserInfoFromToken();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState("panel-1");

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const handleChangePage = async (page) => {
    setCurrentPage(page);
  };

  const handleChangeSize = async (size) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  const handleSortChange = (sortInfoTable) => {
    const sortId =
      sortInfoTable.id === "description" ? "observacao" : sortInfoTable.id;
    setSortInfo({ id: sortId, type: sortInfoTable.type });
    if (sortInfoTable.type === "") {
      setSortInfo({ id: "", type: "" });
    }
  };

  const columns = [
    { id: "creationDate", label: "Data", date: true, isSort: true },
    { id: "user", label: "Usuário", big: true },
    { id: "balance", label: "Saldo", orderRight: true },
  ];

  const dataTable = {
    content: [
      {
        creationDate: "2024-07-31T00:00:00",
        user: "Nome do usuário completo",
        balance: "1:23",
      },
      {
        creationDate: "2024-07-31T00:00:00",
        user: "Nome do usuário completo",
        balance: "1:23",
      },
      {
        creationDate: "2024-07-31T00:00:00",
        user: "Nome do usuário completo",
        balance: "1:23",
      },
      {
        creationDate: "2024-07-31T00:00:00",
        user: "Nome do usuário completo",
        balance: "1:23",
      },
      {
        creationDate: "2024-07-31T00:00:00",
        user: "Nome do usuário completo",
        balance: "1:23",
      },
    ],
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Typography variant="h4" marginY={5}>
          {reportDetails.title}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={2}
      >
        <Box display="flex" flexDirection="column" gap={2} width="65%">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="p7">Consultar saldo de horas</Typography>
            <ButtonComponent
              title="Registrar horas"
              icon="new"
              onClick={() => setModalRegister(true)}
            />
          </Box>

          <Box className="br-tab">
            {userInfo?.roles.includes("supervisor") && (
              <Box className="tab-nav">
                <ul>
                  <li
                    className={`tab-item ${
                      activeTab === "panel-1" ? "is-active" : ""
                    }`}
                    title="Meu saldo de horas"
                  >
                    <button
                      type="button"
                      onClick={() => handleTabChange("panel-1")}
                      data-panel="panel-1"
                    >
                      <span className="name">Meu saldo de horas</span>
                    </button>
                  </li>

                  <li
                    className={`tab-item ${
                      activeTab === "panel-2" ? "is-active" : ""
                    }`}
                    title="Todos"
                  >
                    <button
                      type="button"
                      onClick={() => handleTabChange("panel-2")}
                      data-panel="panel-2"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span className="name">Todos</span>
                    </button>
                  </li>
                </ul>
              </Box>
            )}

            <Box className="tab-content">
              <div
                className={`tab-panel ${
                  activeTab === "panel-1" ? "active" : ""
                }`}
                id="panel-1"
              >
                <TableComponent
                  columns={columns}
                  data={dataTable}
                  changeSize={handleChangeSize}
                  changePage={handleChangePage}
                  onSortChange={handleSortChange}
                  onClickDelete={() => setModalDelete(true)}
                  onClickEdit={() => setModalEdit(true)}
                  actions={["edit", "delete"]}
                />
              </div>
              {userInfo?.roles.includes("supervisor") && (
                <div
                  className={`tab-panel ${
                    activeTab === "panel-2" ? "active" : ""
                  }`}
                  id="panel-2"
                >
                  <TableComponent
                    columns={columns}
                    data={dataTable}
                    changeSize={handleChangeSize}
                    changePage={handleChangePage}
                    onSortChange={handleSortChange}
                    onClickDelete={() => setModalDelete(true)}
                    onClickEdit={() => setModalEdit(true)}
                    actions={["edit", "delete"]}
                  />
                </div>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          width="35%"
          borderRadius={1}
          boxShadow={3}
          padding={2}
        >
          <Typography variant="h2">Observação</Typography>
          <Box
            borderRadius={1}
            padding={2}
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ backgroundColor: theme.customColors.greyWarm100 }}
          >
            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
            <Typography variant="p">
              Apenas o supervisor pode visualizar o saldo de horas de todos os
              usuários que editaram o relatório.
            </Typography>
          </Box>
        </Box>
      </Box>
      <ModalDeleteBalanceHour
        openModal={modalDelete}
        closeModal={() => setModalDelete(false)}
        handleDelete={() => setModalDelete(false)}
      />
      <ModalEditBalanceHour
        openModal={modalEdit}
        closeModal={() => setModalEdit(false)}
        onFormSubmit={() => setModalEdit(false)}
      />
      <ModalRegisterBalanceHour
        openModal={modalRegister}
        closeModal={() => setModalRegister(false)}
        onFormSubmit={() => setModalRegister(false)}
      />
    </Box>
  );
}
