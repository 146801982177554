import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import DefaultLayout from "../layout/DefaultLayout/DefaultLayout";
import EditorHome from "../pages/EditorHome/EditorHome";
import Login, { loginLoader } from "../pages/Login/Login";
import ReportsList, { reportsLoader } from "../pages/Reports/ReportsList";
import NewReport, { newReportsLoader } from "../pages/Reports/NewReport";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "../pages/NotFound/NotFound";
import ReportDetails, {
  reportsDetailsLoader,
} from "../pages/Reports/ReportDetails";
import AssociationReport, {
  associationDetailsLoader,
} from "../pages/Reports/AssociationReport";
import RoleProtectedRoute from "./RoleProtectedRoute";
import EditReport, { editReportLoader } from "../pages/Reports/EditReport";
import BalanceHours from "../pages/Reports/BalanceHours";
import ResetInitial from "../pages/Login/ResetInitial";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Login />} loader={loginLoader} />
      <Route
        path="/resetar-senha"
        element={
          <ProtectedRoute>
            <ResetInitial />
          </ProtectedRoute>
        }
      />
      <Route element={<DefaultLayout />}>
        {/* <Route */}
        {/*  path="dashboard" */}
        {/*  element={ */}
        {/*    <ProtectedRoute> */}
        {/*      <EditorHome /> */}
        {/*    </ProtectedRoute> */}
        {/*  } */}
        {/* /> */}
        <Route path="relatorios">
          <Route
            index
            element={
              <ProtectedRoute>
                <ReportsList />
              </ProtectedRoute>
            }
            loader={reportsLoader}
          />
          <Route
            path="novo-relatorio"
            element={
              <RoleProtectedRoute allowedRoles={["supervisor"]}>
                <NewReport />
              </RoleProtectedRoute>
            }
            loader={newReportsLoader}
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute>
                <ReportDetails />
              </ProtectedRoute>
            }
            loader={reportsDetailsLoader}
          />
          <Route
            path=":id/editar-visualizar"
            element={
              <ProtectedRoute>
                <EditorHome />
              </ProtectedRoute>
            }
            loader={reportsDetailsLoader}
          />
          <Route
            path=":id/editar-visualizar/associacoes"
            element={
              <ProtectedRoute>
                <AssociationReport />
              </ProtectedRoute>
            }
            loader={associationDetailsLoader}
          />
          <Route
            path=":id/editar-relatorio"
            element={
              <RoleProtectedRoute
                allowedRoles={["supervisor", "administrador"]}
              >
                <EditReport />
              </RoleProtectedRoute>
            }
            loader={editReportLoader}
          />
          <Route
            path=":id/saldo-de-horas"
            element={
              <ProtectedRoute>
                <BalanceHours />
              </ProtectedRoute>
            }
            loader={reportsDetailsLoader}
          />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </>,
  ),
);

export default router;
