import React, { useEffect, useState } from "react";
import { Box, ButtonBase, Tooltip, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { generateHTML } from "@tiptap/react";
import TiptapEditor from "../Editor/TiptapEditor";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import EditTitleModal from "./components/EditTitleModal";
import GenericService from "../../services/GenericService";
import extensions from "../../utils/EditorExtensions";
import { getUserInfoFromToken } from "../../services/Auth";
import HomologReportModal from "../../components/HomologComponents/HomologReportModal";
import AproveDisaproveRequestModal from "../../components/HomologComponents/AproveDisaproveRequestModal";
import ReturnReportModal from "../../components/HomologComponents/ReturnReportModal";
import RequestHomologModal from "../../components/HomologComponents/RequestHomologModal";
import { useSnackbar } from "../../context/SnackbarProvider";

export default function EditorHome() {
  const [modalEditTitle, setModalEditTitle] = useState(false);
  const [modalHomologReport, setModalHomologReport] = useState(false);
  const [modalRequestHomologReport, setModalRequestHomologReport] =
    useState(false);
  const [modalAproveHomologReport, setModalAproveHomologReport] =
    useState(false);
  const [modalReturnHomologReport, setModalReturnHomologReport] =
    useState(false);
  const [dateSave, setDateSave] = useState("");
  const theme = useTheme();
  const navigate = useNavigate();
  const report = useLoaderData();
  const [reportDetails, setReportDetails] = useState(null);
  const [updateKey, setUpdateKey] = useState(0);
  const [jsonOutput, setJsonOutput] = useState([]);
  const [ordersToHomologate, setOrdersToHomologate] = useState([]);
  const { id } = useParams();
  const [content, setContent] = useState(null);
  const userInfo = getUserInfoFromToken();
  const { showSnackbar } = useSnackbar();
  // const location = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getReportsDetails = async () => {
    const response = await GenericService.findOne("find-reporting", id);
    if (response.status === 200) {
      await setReportDetails(response.data);
    }
  };

  const getOrdersToHomologate = async () => {
    const response = await GenericService.update("to-homologate");
    setOrdersToHomologate(response.data);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getReportsDetails();
    getOrdersToHomologate();
  }, []);

  const handleAssociation = () => {
    navigate("associacoes", { replace: true });
  };

  const handleHomolog = () => {
    if (userInfo.roles.includes("pesquisador")) {
      setModalRequestHomologReport(true);
    } else if (
      reportDetails.status === "Aguardando Homologação" &&
      (userInfo.roles.includes("supervisor") ||
        userInfo.roles.includes("administrador"))
    ) {
      setModalHomologReport(true);
    }
  };

  const handleUpdateTitle = async (newTitle) => {
    try {
      const updatedReport = { id: reportDetails.id, title: newTitle };
      const response = await GenericService.update("put-order", updatedReport);

      if (response.status === 200) {
        setReportDetails(response.data);
        setModalEditTitle(false);
        showSnackbar({
          success: true,
          message: "Titulo do relatório editado com sucesso!",
        });
      } else {
        showSnackbar({
          error: true,
          message: "Erro ao atualizar o título!",
        });
      }
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro ao atualizar o título: ${error}`,
      });
    }
  };

  const handleSaveAutomatic = (date) => {
    setDateSave(`Salvo automaticamente em 
    ${new Intl.DateTimeFormat("pt-BR", { year: "numeric", month: "2-digit", day: "2-digit" }).format(date)} 
    às
    ${new Intl.DateTimeFormat("en-US", { hour: "2-digit", minute: "2-digit", second: "2-digit", hourCycle: "h23" }).format(date)}`);
  };

  const handleSaveByUSer = async () => {
    try {
      const header = {
        isRascunho: "false",
      };

      const response = await GenericService.saveDocument(
        "save-document",
        reportDetails.id,
        jsonOutput,
        header,
      );

      if (response.status === 202) {
        window.localStorage.setItem("content", "");
        showSnackbar({
          success: true,
          message: "Relatório salvo com sucesso!",
        });
      } else {
        showSnackbar({
          error: true,
          message: "Erro ao salvar um relatório!",
        });
      }
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro ao salvar o relatório: ${error}`,
      });
    }
  };

  const handleRequestHomolog = async () => {
    try {
      const response = await GenericService.update(
        `homologate/${reportDetails.id}`,
      );
      if (response.status === 200) {
        setModalRequestHomologReport(false);
        showSnackbar({
          success: true,
          message: "Relatório enviado para homologação!",
          timestamp: true,
        });
        navigate("/relatorios");
      }
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro ao enviar o relatório pra homologação: ${error}`,
      });
    }
  };

  const handleAproveDisaproveHomolog = async ({ status, justify, date }) => {
    try {
      const formattedDate =
        date instanceof Date ? date.toISOString().split("Z")[0] : "";

      const orderToHomologate = ordersToHomologate.find(
        (order) => order.order_id === reportDetails.id,
      );
      const idToUse = orderToHomologate
        ? orderToHomologate.id
        : reportDetails.id;

      const response = await GenericService.update(`homologate/avaluating`, {
        id: idToUse,
        justify,
        dateHomologate: formattedDate,
        isAproved: status,
      });

      if (response.status === 200) {
        setModalReturnHomologReport(false);
        setModalAproveHomologReport(false);
        showSnackbar({
          success: true,
          message:
            status === true
              ? "Relatório homologado com sucesso!"
              : "Relatório devolvido ao pesquisador!",
          timestamp: true,
        });
        navigate("/relatorios");
      }
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro: ${error}`,
      });
    }
  };

  useEffect(() => {
    const getContent = async () => {
      try {
        const header = {
          isRascunho: true,
        };
        const response = await GenericService.findAllList(
          `getdata-object/${id}`,
          header,
        );
        if (response.status === 200) {
          const { data } = response;
          const dataStringify = JSON.stringify(data);
          if (JSON.parse(dataStringify)) {
            if (dataStringify.startsWith('{"pesquisador":')) {
              const contentResponse = {
                type: "doc",
                content: [
                  {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: " ",
                      },
                    ],
                  },
                ],
              };
              setContent(generateHTML(contentResponse, [...extensions]));
            } else if (data === "[]") {
              setContent("");
            } else {
              let JSONtoHTML = generateHTML(data, [...extensions]);
              JSONtoHTML = JSONtoHTML.replace(
                "    ",
                "&nbsp;&nbsp;&nbsp;&nbsp;",
              );
              setContent(JSONtoHTML);
            }
          }
        } else if (response.status === 500) {
          showSnackbar({
            error: true,
            message: `Erro ao recuperar relatório`,
          });
        }
      } catch (error) {
        showSnackbar({
          error: true,
          message: `Erro ao recuperar relatório: ${error}`,
        });
      }
    };
    // if (location.state.location !== location.key) {
    //   window.localStorage.removeItem("content");
    //   window.localStorage.removeItem("reportId");
    getContent();
    //   location.state.location = location.key;
    // }
  }, []);

  return (
    <div>
      {reportDetails && (
        <Box>
          <Box marginY={5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={3}
            >
              <Box display="flex">
                <Typography variant="h4">
                  {reportDetails.title || "NA"}
                </Typography>
                {reportDetails.status !== "Homologado" && (
                  <ButtonBase
                    onClick={() => setModalEditTitle(true)}
                    style={{ marginLeft: "20px" }}
                    disableRipple
                  >
                    <Tooltip title="Editar">
                      <FontAwesomeIcon
                        icon={faEdit}
                        color={theme.customColors.blueWarm700}
                      />
                    </Tooltip>
                  </ButtonBase>
                )}
              </Box>

              <Box display="flex" gap={1}>
                {userInfo?.roles.includes("pesquisador") &&
                  reportDetails.status === "Em elaboração" && (
                    <ButtonComponent
                      title="Homologar"
                      buttonType="secondary"
                      icon="associationHomolog"
                      onClick={handleHomolog}
                    />
                  )}
                {reportDetails.status === "Aguardando Homologação" &&
                  (userInfo?.roles.includes("supervisor") ||
                    userInfo?.roles.includes("administrador")) && (
                    <ButtonComponent
                      title="Homologar"
                      buttonType="secondary"
                      icon="associationHomolog"
                      onClick={handleHomolog}
                    />
                  )}
                <ButtonComponent
                  title="Associações"
                  buttonType="secondary"
                  icon="people"
                  onClick={() => handleAssociation()}
                />
                {reportDetails.status === "Em elaboração" && (
                  <ButtonComponent
                    title="Salvar"
                    icon="save"
                    onClick={() => handleSaveByUSer()}
                  />
                )}
              </Box>
            </Box>
            <Box marginTop={1}>
              <Typography variant="p3">{dateSave}</Typography>
            </Box>
          </Box>
          <Box marginBottom={2}>
            {reportDetails && (
              <TiptapEditor
                key={updateKey}
                handleSaveAutomatic={handleSaveAutomatic}
                setJsonOutput={setJsonOutput}
                reportDetails={report}
                content={content}
                setContent={setContent}
              />
            )}
          </Box>
          <EditTitleModal
            openModal={modalEditTitle}
            closeModal={() => setModalEditTitle(false)}
            editedTitle={(formData) => {
              handleUpdateTitle(formData.title);
            }}
          />
          <RequestHomologModal
            closeModal={() => setModalRequestHomologReport(false)}
            openModal={modalRequestHomologReport}
            report={reportDetails}
            handleHomolog={handleRequestHomolog}
          />

          <AproveDisaproveRequestModal
            openModal={modalHomologReport}
            closeModal={() => setModalHomologReport(false)}
            handleAprove={() => {
              setModalHomologReport(false);
              setModalAproveHomologReport(true);
            }}
            handleDisaprove={() => {
              setModalHomologReport(false);
              setModalReturnHomologReport(true);
            }}
            content={reportDetails}
          />

          <HomologReportModal
            openModal={modalAproveHomologReport}
            closeModal={() => setModalAproveHomologReport(false)}
            handleHomolog={(data) => {
              handleAproveDisaproveHomolog({
                date: data["Data inicial"]?.[0],
                status: true,
                justify: "Aprovado para Homologação",
              });
            }}
            content={reportDetails}
          />

          <ReturnReportModal
            openModal={modalReturnHomologReport}
            closeModal={() => setModalReturnHomologReport(false)}
            handleReturn={(data) =>
              handleAproveDisaproveHomolog({
                justify: data.reason,
                status: false,
              })
            }
            content={reportDetails}
          />
        </Box>
      )}
    </div>
  );
}
