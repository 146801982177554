import { Extension } from "@tiptap/react";

const  FontSize = Extension.create({
    name: 'fontSize',
    addOptions(){
        return{
            types: ['textStyle'],
        };
    },
    addGlobalAttributes(){
        return[{
            types: this.options.types,
            attributes: {
                fontSize: {
                    default: null,
                    parseHTML: element => element.style.fontSize.replace(/['"]+/g, ''),
                    renderHTML: attributes => {
                        if(!attributes.fontSize){
                            return {};
                        }
                        return{
                            style: `font-size: ${attributes.fontSize}`,
                        };
                    },
                },
            },
        }];
    },
    addCommands(){
        return {
            setFontSize: fontSize => ({chain}) => chain()
                .setMark('textStyle', {fontSize: `${fontSize}pt`})
                .run(),
            unsetFontSize: () => ({chain}) => chain()
                .setMark('textStyle', {fontSize: null})
                .removeEmptyTextStyle()
                .run()
        };
    },
});

export default FontSize;