import React, { useEffect, useState } from "react";
import {
    Button,
    Tooltip,
    ToggleButton,
    Popover,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBold,
    faItalic,
    faStrikethrough,
    faPaintBrush,
    faUnderline
} from "@fortawesome/free-solid-svg-icons";
import StyledToggleButtonGroup from "../StyledToggleButtonGroup";
import FontColorToolbarEditor from "../FontColorToolbarEditor"

const style = {
  width: 40,
  height: 40,
  gap: "8px",
  padding: "0px 10px 0px 10px",
  borderRadius: "4px",
  border: "1px solid #888888 !important",
  minWidth: 0,
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#888888"
  }
}

function TextStyles(props){

    const {editor, content} = props;

    const [formats, setFormats] = useState(() => ["italic"]);
    const [anchorEl, setAnchorEl] = useState(null);
    let isPopoverCliked = Boolean(false);
    const openPopover = Boolean(anchorEl);
    const popoverId = openPopover ? "simple-popover" : undefined;
    const [fontColor, setFontColor] = useState({ color: "#000000" });


    const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
    };

    const handlePopoverClick = (event) => {
        isPopoverCliked = true;
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        isPopoverCliked = false;
        setAnchorEl(null);
      };

    useEffect(() => {
        if(JSON.stringify(editor?.getAttributes('textStyle')) === '{}' || content === '<p></p>'){
            setFontColor({ color: "#000000" })
        }
    }, [editor, fontColor.color, content])

    return(
        <StyledToggleButtonGroup
            size="small"
            value={formats}
            onChange={handleFormat}
            aria-label="text formatting"
            sx={{
                alignItems: 'center',
                padding: "0px 4px 0px 4px",
                height: 40
            }}
      >
        
        <Tooltip title="Negrito" placement="top">
          <ToggleButton
            value="bold"
            aria-label="bold"
            onClick={() => editor.chain().focus().toggleBold().run()}
            selected={editor?.isActive("bold")}
            sx={style}
          >
            <FontAwesomeIcon icon={faBold} />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Itálico" placement="top">
          <ToggleButton
            value="italic"
            aria-label="italic"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            selected={editor?.isActive("italic")}
            sx={style}
          >
            <FontAwesomeIcon icon={faItalic} />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Sublinhado" placement="top">
          <ToggleButton
            value="underline"
            aria-label="underline"
            selected={editor?.isActive("underline")}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            sx={style}
          >
            <FontAwesomeIcon icon={faUnderline} />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Tachado" placement="top">
          <ToggleButton
            value="strike"
            aria-label="strike"
            selected={editor?.isActive("strike")}
            onClick={() => editor.chain().focus().toggleStrike().run()}
            sx={style}
          >
            <FontAwesomeIcon icon={faStrikethrough} />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Alterar a cor da fonte" placement="top" style={{margin: 4}}>
        <Popover
            id={popoverId}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
              marginLeft: '4px !important',
              marginRight: '4px',
            }}
        >
            
            <FontColorToolbarEditor editor={editor} src="toolbar" fontColor={fontColor} setFontColor={setFontColor}/>
        </Popover>
          <Button
            aria-describedby={popoverId}
            onClick={handlePopoverClick}
            value={isPopoverCliked}
            sx={{ 
              color: fontColor.color === '#000000'? '#888888' : fontColor.color,
              ...style
              
            }}
          >
            <FontAwesomeIcon icon={faPaintBrush} style={{width: "20px", height: "16px", alignItems: "center"}}/>
          </Button>
        </Tooltip>
      </StyledToggleButtonGroup>
    );
}

export default TextStyles;