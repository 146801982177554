import { Box, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { format, parseISO } from "date-fns";

export default function ReportNotApprovedComponent({ content, report }) {
  const theme = useTheme();

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "dd/MM/yyyy 'às' HH:mm");
  };

  return (
    <Box boxShadow={3} padding={2}>
      <Box
        sx={{
          backgroundColor: theme.customColors.greyWarm100,
          display: "flex",
          alignItems: "center",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 20 }} />
        <Box
          marginLeft={2}
          flexGrow={1}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography variant="p">
            O pedido de homologação foi negado por{" "}
            {report?.supervisor?.username
              ? report.supervisor.username
              : "um supervisor desconhecido"}{" "}
            em{" "}
            {report?.updateDate
              ? formatDate(report.updateDate)
              : "data desconhecida"}
            .
          </Typography>

          <Typography variant="p">Motivo: {content.justify}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
