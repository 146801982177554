import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const iconMap = {
  clock: "fa-clock",
  file: "fa-file",
  history: "fa-history",
  edit: "fa-edit",
};

export default function MenuDropdown({ options }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (route) => {
    setAnchorEl(null);
    navigate(route);
  };

  const ITEM_HEIGHT = 48;

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FontAwesomeIcon
          icon={faEllipsisV}
          style={{ fontSize: "20px" }}
          color={theme.customColors.blueWarm700}
        />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "280px",
              borderRadius: 0,
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.title}
            onClick={() => handleMenuItemClick(option.route)}
            style={{
              padding: "12px 24px",
              borderBottom:
                index < options.length - 1
                  ? `1px solid ${theme.customColors.greyWarm200}`
                  : "none",
            }}
          >
            <ListItemIcon>
              <i
                className={`fas ${iconMap[option.icon]}`}
                aria-hidden="true"
                style={{ color: theme.customColors.greyWarm800, fontSize: 16 }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontWeight: 600,
                fontSize: 14,
                color: theme.customColors.greyWarm800,
              }}
            >
              {option.title}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
