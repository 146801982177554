import React from "react";
import {
  Box,
  Divider,
  MenuItem,
  Select,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default function TableFooter(props) {
  const theme = useTheme();
  const {
    count,
    currentPage,
    totalPages,
    rowsPerPage,
    setCurrentPage,
    setRowsPerPage,
  } = props;
  const from = rowsPerPage * currentPage + 1;
  const to = Math.min(rowsPerPage * (currentPage + 1), count);
  const range = `${from}-${to} de ${count} itens`;

  const handleChangePage = (event) => {
    setCurrentPage(parseInt(event.target.value, 10));
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <Toolbar
      sx={{
        marginY: 1,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="p3">Exibir</Typography>
        <Select
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          displayEmpty
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSvgIcon-root": {
              color: theme.customColors.blueWarm700,
            },
          }}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
        </Select>
        <Divider orientation="vertical" sx={{ marginX: 1.5, height: "2rem" }} />
        <Typography variant="p3" sx={{ flexShrink: 0 }}>
          {range}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography variant="p3">Página</Typography>
        <Select
          value={currentPage}
          onChange={handleChangePage}
          displayEmpty
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSvgIcon-root": {
              color: theme.customColors.blueWarm700,
            },
          }}
        >
          {[...Array(totalPages).keys()].map((pageIndex) => (
            <MenuItem key={pageIndex} value={pageIndex}>
              {pageIndex + 1}
            </MenuItem>
          ))}
        </Select>
        <Divider orientation="vertical" sx={{ marginX: 1.5, height: "2rem" }} />
        <FontAwesomeIcon
          icon={faChevronLeft}
          color={theme.customColors.blueWarm700}
          onClick={handlePreviousPage}
          style={{
            cursor: currentPage > 0 ? "pointer" : "default",
            opacity: currentPage > 0 ? 1 : 0.5,
          }}
        />
        <FontAwesomeIcon
          icon={faChevronRight}
          color={theme.customColors.blueWarm700}
          onClick={handleNextPage}
          style={{
            cursor: currentPage < totalPages - 1 ? "pointer" : "default",
            opacity: currentPage < totalPages - 1 ? 1 : 0.5,
          }}
        />
      </Box>
    </Toolbar>
  );
}
