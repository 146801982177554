import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

export default function NotFound() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: "#0C326F",
      }}
      height="100vh"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          width={408}
          sx={{ backgroundColor: theme.customColors.white }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography>Página não encontrada</Typography>
          <Typography>
            Retonar para o <Link to="/">Sistema</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
