import React from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import theme from "./theme/theme";
import router from "./Routes/Router";
import { RouteHistoryProvider } from "./Routes/RouteHistoryProvider";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouteHistoryProvider>
        <RouterProvider router={router} />
      </RouteHistoryProvider>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
