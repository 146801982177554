import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faFilter,
  faHistory,
  faPlus,
  faPollH,
  faSave,
  faThumbsUp,
  faTrash,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import React from "react";

const iconMap = {
  new: "fa-plus",
  filter: "fa-filter",
  file: "fa-file",
  history: "fa-history",
  save: "fa-save",
  like: "fa-thumbs-up",
  people: "fa-users",
  delete: "fa-trash",
  associationHomolog: "fa-poll-h",
  check: "fa-check",
  edit: "fa-edit",
  cross: "fa-times",
};

export default function ButtonComponent({
  title,
  buttonType,
  submit,
  icon,
  onClick,
  deleteColor,
}) {
  return (
    <button
      className={`br-button ${buttonType || "primary"}`}
      type={submit ? "submit" : "button"}
      onClick={onClick}
      style={deleteColor ? { backgroundColor: "#E52207" } : {}}
    >
      {icon && <i className={`fas ${iconMap[icon]} mr-2`} aria-hidden="true" />}

      {title}
    </button>
  );
}
