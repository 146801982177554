/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React, { useContext, useEffect } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLinesVertical } from "@fortawesome/free-solid-svg-icons";
// eslint-disable-next-line import/no-extraneous-dependencies
import cryptoJs from 'crypto-js';
import EditorContext from '../../context/Editor/EditorContext';

export default function ImageResize (props) {
    
    const {styleImage, setStyleImage, setIdImageSelect} = useContext(EditorContext); 

    const showResize = props.selected;
    // console.log(props.node.attrs.src)

    // console.log(props.node.attrs.src.includes('data:image/png;base64'))
    // sha256()
    const imageId = cryptoJs.SHA256(props.node.attrs.src).toString(); 
    // props.node.attrs.src.replace('blob:http://localhost:3000/', ''); // data:image/png;base64
    const imageClass = `postimage-${imageId}`;
    const styleImageObj = Object.values(styleImage); 

    const handler = (mouseDownEvent) => {
        const parent = mouseDownEvent.target.closest('.image');
        const image = parent?.querySelector(`img.${imageClass}`) ?? null;
        if (image === null) return;
        
        const startSize = { x: image.clientWidth, y: image.clientHeight };
        const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

        function onMouseMove(mouseMoveEvent) {
            props.updateAttributes({
                width: startSize.x - startPosition.x + mouseMoveEvent.pageX,
                height: startSize.y - startPosition.y + mouseMoveEvent.pageY,
            });
            document.getElementsByClassName("icon-resize")[0].style.cursor = 'col-resize'
        }
        function onMouseUp() {
            document.body.removeEventListener('mousemove', onMouseMove);
        }

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp, { once: true });
    };

    useEffect(() => {
        const index = styleImageObj.findIndex(img => img.id === imageId);
        if(index === -1){
            const newStyleImage = [
                ...styleImage,
                {
                    id: imageId,
                    style: JSON.parse(JSON.stringify(styleImage[0].style))
                }
            ];
            setStyleImage(newStyleImage);
        }

    }, [imageId, setStyleImage, styleImage, styleImageObj])

    useEffect(() => {
        if(showResize){
            setIdImageSelect(imageId)
        }else{
            setIdImageSelect('');
        }
    },[imageId, setIdImageSelect, showResize])

    return (
        <NodeViewWrapper id={imageId} className="image" style={styleImageObj.filter(img => img.id === imageId)[0]?.style}> 
            <div className="resize-trigger">
                <img {...props.node.attrs} className={imageClass} contentEditable="false" suppressContentEditableWarning="true"/>
                {showResize && (
                    <div className='icon-resize' onMouseDown={handler}>
                        <FontAwesomeIcon icon={faGripLinesVertical} />
                    </div>
                )}
            </div>
            <p contentEditable="true" suppressContentEditableWarning="true">{props.node.attrs.reference}</p>
        </NodeViewWrapper>
    );
};
