import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Modal } from "antd";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectComponent from "../../../components/SelectComponent/SelectComponent";
import InputComponent from "../../../components/InputComponent/InputComponent";
import TextArea from "../../../components/TextArea/TextArea";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import "./style.css";

const newAssociationFormValidationSchema = yup.object({
  type: yup.object().required("O campo ”tipo” não pode ficar vazio"),
  typeDescription: yup.string().required("O campo ”tipo” não pode ficar vazio"),
  indicM: yup.object().required("O campo ”Índice M” não pode ficar vazio"),
  indicP: yup.object().required("O campo ”Índice P” não pode ficar vazio"),
  observation: yup.string(),
});

const typeOptions = [
  { value: 1, name: "CPF", mask: "999.999.999-99" },
  { value: 2, name: "PLACA", mask: "*** 9*99" },
  { value: 3, name: "CONTAINER", mask: "****999999-9" },
  { value: 4, name: "ENDEREÇO" },
  { value: 5, name: "TELEFONE", mask: "(99)9999-99999" },
  { value: 6, name: "OUTROS" },
];

const mAndPIndex = [
  { value: 1, name: "1" },
  { value: 2, name: "2" },
  { value: 3, name: "3" },
  { value: 4, name: "4" },
  { value: 5, name: "5" },
];

export default function ModalEditAssociationReport({
  openModal,
  closeModal,
  onFormSubmit,
  association,
}) {
  const { handleSubmit, control, watch, setValue, reset } = useForm({
    resolver: yupResolver(newAssociationFormValidationSchema),
  });

  useEffect(() => {
    if (association) {
      setValue(
        "type",
        typeOptions.find((option) => option.name === association.type),
      );
      setValue("typeDescription", association.typeDescription);
      setValue(
        "indicM",
        mAndPIndex.find((option) => option.value === association.indicM),
      );
      setValue(
        "indicP",
        mAndPIndex.find((option) => option.value === association.indicP),
      );
      setValue("observation", association.observation);
    }
  }, [association, setValue]);

  const selectedType = watch("type");
  const typeSelected = selectedType
    ? typeOptions.find((option) => option.name === selectedType.name)
    : null;

  useEffect(() => {
    if (selectedType && association && selectedType.name !== association.type) {
      setValue("typeDescription", "");
    }
  }, [selectedType, setValue, association]);

  const handleClearFilters = () => {
    reset();
    closeModal();
  };

  function handleAplicar(data) {
    onFormSubmit(data);
    reset();
    closeModal();
  }

  return (
    <Modal
      title={
        <span style={{ fontFamily: "Rawline", fontWeight: 600 }}>
          Editar associação
        </span>
      }
      open={openModal}
      width={600}
      onOk={closeModal}
      onCancel={() => {
        reset();
        closeModal();
      }}
      footer={null}
    >
      <form onSubmit={handleSubmit(handleAplicar)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          marginTop={3}
        >
          <Box>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <SelectComponent
                  label="Tipo"
                  placeholder="Selecionar item"
                  options={typeOptions}
                  {...field}
                  selectHelperText={error?.message}
                  isReadOnly
                />
              )}
            />
            <Controller
              name="typeDescription"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputComponent
                  {...field}
                  placeholder={typeSelected ? typeSelected.name : ""}
                  mask={typeSelected ? typeSelected.mask : undefined}
                  inputHelperText={error?.message}
                />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Controller
              name="indicM"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <SelectComponent
                  label="Índice M"
                  placeholder="Selecionar item"
                  options={mAndPIndex}
                  {...field}
                  selectHelperText={error?.message}
                  isReadOnly
                />
              )}
            />
            <Controller
              name="indicP"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <SelectComponent
                  label="Índice P"
                  placeholder="Selecionar item"
                  options={mAndPIndex}
                  {...field}
                  selectHelperText={error?.message}
                  isReadOnly
                />
              )}
            />
          </Box>
          <Controller
            name="observation"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextArea
                label="Observação"
                placeholder="Insira sua observação..."
                maxLength={500}
                {...field}
              />
            )}
          />
          <Box display="flex" justifyContent="flex-end" marginTop={1} gap={2}>
            <ButtonComponent
              buttonType="secondary"
              title="Descartar alterações"
              onClick={handleClearFilters}
            />
            <ButtonComponent title="Salvar" icon="save" submit />
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
