import React from "react";
import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const filterNames = {
  archived: "Incluir arquivado",
  secondDate: "Data Final",
  firstDate: "Data inicial",
  statusFilter: "Status",
  teamFilter: "Time",
  textTitle: "Título",
  supervisor: "Supervisor",
  typeOff: "Tipo",
  author: "Autor",
};

export default function Filter({ name, value, onRemove }) {
  const theme = useTheme();

  return (
    <Box
      height={36}
      borderRadius={1}
      sx={{
        backgroundColor: theme.customColors.greyWarm50,
      }}
      display="flex"
      gap={1}
      alignItems="center"
      padding={1}
    >
      <Typography
        variant="p4"
        color={theme.customColors.blueWarm700}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "20ch",
        }}
      >
        {filterNames[name] || name}: {value}
      </Typography>
      <ButtonBase onClick={onRemove}>
        <FontAwesomeIcon
          icon={faTimes}
          color={theme.customColors.blueWarm700}
        />
      </ButtonBase>
    </Box>
  );
}
