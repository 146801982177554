import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import InputComponent from "../../components/InputComponent/InputComponent";
import authApi from "../../services/AuthApi";
import { login } from "../../services/Auth";
import packageJson from "../../../package.json";
import MessageComponent from "../../components/MessageComponent/MessageComponent";
import GenericService from "../../services/GenericService";

export const loginLoader = async ({ params }) => {
  try {
    const response = await GenericService.findAllList("version");
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

const newLoginFormValidationSchema = yup.object({
  username: yup.string().required("O campo ”usuário” não pode ficar vazio"),
  password: yup.string().required("O campo ”senha” não pode ficar vazio"),
});

export default function Login() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleSubmit, reset, formState, control } = useForm({
    resolver: yupResolver(newLoginFormValidationSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const versionAPI = useLoaderData();

  const handleLogin = async (data) => {
    setIsLoading(true);
    localStorage.removeItem("id_token");
    try {
      const loginData = await GenericService.login(data);

      if (loginData && loginData.accessToken) {
        await login(loginData.accessToken);
        navigate("/relatorios");
      } else {
        setErrorLogin(true);
      }
    } catch (error) {
      setErrorLogin(true);
    }
    setIsLoading(false);
    reset();
  };

  async function verifyLogin() {
    const response = await GenericService.findAllList(`listall-report`);
    if (response.status === 200) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    verifyLogin().then((isLoggedIn) => {
      if (isLoggedIn) {
        navigate("/relatorios");
      }
    });
  }, [navigate]);

  return (
    <Box
      sx={{
        backgroundColor: "#0C326F",
      }}
      height="100vh"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <form onSubmit={handleSubmit(handleLogin)}>
          <Box
            // height={487}
            width={408}
            sx={{ backgroundColor: theme.customColors.white }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box padding="28px 0 28px 0">
              <Typography variant="h3">Acesso ao sistema</Typography>
            </Box>
            {errorLogin && (
              <Box padding="0 20px 0 20px">
                <MessageComponent
                  type="Erro"
                  message="Verifique se os dados de acesso estão corretos. Em caso de dúvida, entre em contato com o administrador."
                  handleClose={() => setErrorLogin(false)}
                />
              </Box>
            )}
            <Box
              gap="15px"
              display="flex"
              flexDirection="column"
              width="100%"
              padding="0 20px 20px 20px"
            >
              <Controller
                name="username"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <InputComponent
                    label="Usuário"
                    type="user"
                    placeholder="Digite seu usuário"
                    error={error || errorLogin ? "danger" : ""}
                    inputHelperText={error?.message}
                    mask="999.999.999-99"
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <InputComponent
                    label="Senha"
                    type="password"
                    placeholder="Digite sua senha"
                    error={error || errorLogin ? "danger" : ""}
                    inputHelperText={error?.message}
                    {...field}
                  />
                )}
              />

              <Typography variant="p" width="calc(100% - 48px)">
                Para o primeiro acesso, utilize a senha temporária gerada pelo
                administrador.
              </Typography>
              <LoadingButton
                variant="contained"
                sx={{
                  width: 360,
                  height: 40,
                  borderRadius: 5,
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: 16.8,
                  "&.MuiLoadingButton-loading": {
                    backgroundColor: "primary.main",
                  },
                  ".MuiLoadingButton-loadingIndicator": {
                    color: "white",
                  },
                }}
                type="submit"
                loading={isLoading}
              >
                Entrar
              </LoadingButton>
            </Box>
          </Box>
        </form>
        <Box display="flex" gap={2} padding={5}>
          <Typography variant="p3" color="white">
            API: v{versionAPI || "NA"}
          </Typography>
          <Typography variant="p3" color="white">
            WEB: v{packageJson.version}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
