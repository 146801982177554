import React from "react";
import {
    ReactNodeViewRenderer,
    mergeAttributes,
    nodeInputRule,
  } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import { Typography } from "@tiptap/extension-typography";
import { TextStyle } from "@tiptap/extension-text-style";
import { FontFamily } from "@tiptap/extension-font-family";
import { Color } from "@tiptap/extension-color";
import { Table } from "@tiptap/extension-table";
import { TableCell } from "@tiptap/extension-table-cell";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableRow } from "@tiptap/extension-table-row";
import Underline from "@tiptap/extension-underline";
import { Image, inputRegex } from "@tiptap/extension-image";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from "prosemirror-state";

import ImageResize from "../components/Editor/ImageResize";
import LiteralTab from "../components/Editor/Extensions/LiteralTab";
import PasteImage from "../components/Editor/Extensions/PasteImage";
import FontSize from "../components/Editor/Extensions/FontSize";

const extensions = [
    StarterKit,
    Typography,
    TextStyle,
    FontFamily,
    FontSize,
    Color.configure({
      types: ["textStyle"],
    }),
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
    Table.configure({
      resizable: true,
      allowTableNodeSelection: true,
    }),
    TableCell.extend({
      addAttributes() {
        return {
          // eslint-disable-next-line react/no-this-in-sfc
          ...this.parent?.(),
          backgroundColor: {
            default: null,
            renderHTML: (attributes) => {
              if (!attributes.backgroundColor) {
                return {};
              }
              return {
                style: `background-color: ${attributes.backgroundColor}`,
              };
            },
            parseHTML: (element) =>
              element.style.backgroundColor.replace(/['"]+/g, ""),
          },
        };
      },
    }),
    TableHeader,
    TableRow,
    Image.configure({
      allowBase64: true,
    }).extend({
      addOptions() {
        return {
          inline: false,
          allowBase64: true,
          HTMLAttributes: {},
          resizeIcon: <>⊙</>,
          reference: "",
        };
      },
      addAttributes() {
        return {
          width: {
            default: "100%",
            renderHTML: (attributes) => ({
              width: attributes.width,
            }),
          },
          height: {
            default: "auto",
            renderHTML: (attributes) => ({
              height: attributes.height,
            }),
          },
          draggable: {
            default: true,
            renderHTML: () => ({}),
          },
          alignItems: {
            default: "",
            renderHTML: (attributes) => ({
              alignItems: attributes.alignItems,
            }),
          },
          src: {
            default: {},
            renderHTML: (attributes) => ({
              src: attributes.src,
            }),
          },
          reference: {
            default: "",
            renderHTML: (attributes) => ({
              reference: attributes.reference,
            }),
          },
        };
      },
      renderHTML({ HTMLAttributes }) {
        // eslint-disable-next-line react/no-this-in-sfc
        return [
          "img",
          // eslint-disable-next-line react/no-this-in-sfc
          mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        ];
      },
      addNodeView() {
        return ReactNodeViewRenderer(ImageResize);
      },
      addInputRules() {
        return [
          nodeInputRule({
            find: inputRegex,
            // eslint-disable-next-line react/no-this-in-sfc
            type: this.type,
            getAttributes: (match) => {
              const [, , alt, src, title, height, width, isDraggable] = match;
              return { src, alt, title, height, width, isDraggable };
            },
          }),
        ];
      },
      addProseMirrorPlugins() {
        return [
          new Plugin({
            props: {
              handleDOMEvents: {
                paste(view, event) {
                  const hasFiles =
                    event.clipboardData &&
                    event.clipboardData.files &&
                    event.clipboardData.files.length;

                  if (!hasFiles) {
                    return;
                  }

                  const images = Array.from(event.clipboardData.files).filter(
                    (file) => /image/i.test(file.type),
                  );

                  if (images.length === 0) {
                    return;
                  }

                  event.preventDefault();

                  const { schema } = view.state;

                  images.forEach((image) => {
                    const reader = new FileReader();

                    reader.onload = (readerEvent) => {
                      const node = schema.nodes.image.create({
                        src: readerEvent.target.result,
                      });
                      const transaction =
                        view.state.tr.replaceSelectionWith(node);
                      view.dispatch(transaction);
                    };
                    reader.readAsDataURL(image);
                  });
                },
              },
            },
          }),
        ];
      },
    }),
    LiteralTab,
    PasteImage,
    Underline,
  ];

  export default extensions;