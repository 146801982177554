import { Box, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { format, parseISO } from "date-fns";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

export default function CancelRequestHomologate({ content, handleCancel }) {
  const theme = useTheme();

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "dd/MM/yyyy 'às' HH:mm");
  };

  return (
    <Box boxShadow={3} padding={2}>
      <Box
        sx={{
          backgroundColor: theme.customColors.greyWarm100,
          display: "flex",
          alignItems: "center",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 20 }} />
        <Box
          marginLeft={2}
          flexGrow={1}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography variant="p">
            Este relatório aguarda homologação
          </Typography>
          <Typography variant="p">
            Solicitação enviada por {content.researcher.username} em{" "}
            {formatDate(content.updateDate)}.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" gap={2} marginTop={2}>
        <ButtonComponent
          title="Cancelar homologação"
          icon="cross"
          deleteColor
          onClick={handleCancel}
        />
      </Box>
    </Box>
  );
}
