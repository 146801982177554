import React from "react";

export default function MessageComponent({ type, message, handleClose }) {
  return (
    <div className={`br-message ${type === "Erro" ? "danger" : "alert"}`}>
      <div className="icon">
        <i className="fas fa-times-circle fa-lg" aria-hidden="true" />
      </div>
      <div className="content" role="alert">
        <span className="message-body"> {message}</span>
      </div>
      <div className="close">
        <button
          className="br-button circle small"
          type="button"
          aria-label="Fechar a messagem alterta"
          onClick={handleClose}
        >
          <i className="fas fa-times" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
