// eslint-disable-next-line import/no-extraneous-dependencies
import { Extension } from "@tiptap/core";
import imagePaste from "../../../utils/ImagePaste";



const PateImage = Extension.create({
    name: 'pasteImage',

    addKeyboardShortcuts(){
        return {
            'Mod-v': async () => {
                imagePaste(this.editor)
            }
        }
    }
})


export default PateImage;