import { Outlet } from "react-router-dom";
import React from "react";
import { Box } from "@mui/material";
import { ToastContainer } from "react-toastify";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Header from "../../components/Header/Header";
import MessageSnackbar from "../../components/Toast/MessageSnackbar";
import { SnackbarProvider } from "../../context/SnackbarProvider";

export default function DefaultLayout() {
  return (
    <div>
      <SnackbarProvider>
        <Header />
        <Box padding="0 40px 0 40px">
          <Breadcrumb />
          <Outlet />
        </Box>
        <MessageSnackbar />
        <ToastContainer />
      </SnackbarProvider>
    </div>
  );
}
