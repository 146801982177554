import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StyledToggleButtonGroup from "../StyledToggleButtonGroup";

const ColorArrowDropDownIcon = styled(ArrowDropDownIcon)({
  color: 'blue', 
});


  const headings = [
    "Corpo",
    "Titulo 1",
    "Titulo 2",
    "Titulo 3",
    "Titulo 4",
    "Titulo 5",
    "Titulo 6",
  ]


function getLevel(value) {

  if(typeof(value) === 'string'){
    const level = value.slice(-1);
    return level === 'o' ? 0 : parseInt(level, 10);
  }
  return value;
    
      
}

function Heading(props){

    const { editor } = props;

    const [alignment, setAlignment] = useState("");
    const [selectedHeadings, setSelectedHeadings] = useState(headings[0]);

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const handleHeadings = (value) => {
        
        if (editor?.isActive("heading", { level: getLevel(value) })) {
          return value;
        }
    
        // eslint-disable-next-line array-callback-return,consistent-return
        headings.map((heading) => {
          if (editor?.isActive("heading", { level: getLevel(heading) })) {
            setSelectedHeadings(heading);
            return heading;
          }
        });
    
        return value !== null ? value : "";
      };

      const handleInputChangeHeading = (event, value) => {
        if ( value !== selectedHeadings){
          setSelectedHeadings(value);
          if (value === 'Corpo') {
            editor?.commands.setParagraph();
          } else {
            editor?.commands.setHeading({...editor?.getAttributes('heading'), level: getLevel(value)});
            editor?.commands.unsetFontSize();
          }
        }
    }
   
    return (
            <StyledToggleButtonGroup
            size="small"
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            sx={{
                alignItems: "left",
                display: "flex",
                flexDirection: "column",
                width: 140, 
                height: 63,

            }}
        >
            <strong style={{width: 37, height: 23, padding: "0px 0px 4px 0px"}}>Estilo</strong>

                <Autocomplete
                options={headings}
                noOptionsText="Sem Opções"
                sx={{ 
                    width: 140, 
                    height: 40,
                    gap: 8,
                    padding: "0px 10px 0px 0px",
                    '& .MuiOutlinedInput-root': { padding: '0', height: '40px' }
                }}
                value={handleHeadings(selectedHeadings)}
                onInputChange={(event, newInputValue) => {
                  handleInputChangeHeading(event, newInputValue)
                }}
                defaultValue={headings[0]}
                renderInput={(params) => <TextField {...params} />}
                openText="Abrir"
                closeText="Fechar"
                clearText="Limpar"
                popupIcon={<ColorArrowDropDownIcon/>}
                />
        </StyledToggleButtonGroup>
    );

}

export default Heading;
